const DEFAULT_COUNTRY_CODE = '+1';

const sanitize = (phoneNumber: string): string => {
  // Remove parenthesis, dashes, and spaces from the phone number
  return phoneNumber.replace(/[\(\)\-\s]/g, '');
};

const validateWithoutCountryCode = (phoneNumber: string): boolean => {
  return /^\d{6,14}$/.test(phoneNumber);
};

// +1123567890 -> good; 1234567890 -> bad
const validateWithCountryCode = (phoneNumber: string): boolean => {
  return /^\+\d{1,3}\d{6,14}$/.test(phoneNumber);
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  // Normalize the phone number
  const normalizedNumber = sanitize(phoneNumber);
  return (
    validateWithCountryCode(normalizedNumber) ||
    validateWithoutCountryCode(normalizedNumber)
  );
};

export const normalizePhoneNumber = (phoneNumber: string): string => {
  let phone = sanitize(phoneNumber);
  const needsCountryCode = (
    validateWithoutCountryCode(phone)
    && !validateWithCountryCode(phone)
  );
  if (needsCountryCode) {
    phone = DEFAULT_COUNTRY_CODE + phone;
  }
  return phone;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  const normalizedNumber = sanitize(phoneNumber);
  if (validateWithCountryCode(normalizedNumber)) {
    return normalizedNumber.replace(/(\d{1,3})(\d{3})(\d{3})(\d{4})/, '$1 $2-$3-$4');
  } else {
    return normalizedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
}