import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import * as Error from '@happenings/components/errors';
import UpdateButton from '../util/UpdateButton';
import ApiErrorWrapper from '../util/ErrorWrapper';

import { MIN_PASSWORD_LENGTH, EMAIL_FMT_PATTERN } from '../../constants';


const SignUpForm = ({ registerNewUser }) => {
  const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onChange' });

  const onSubmit = (event) => {
    const { username, email, password } = event;
    registerNewUser(username, email, password);
  };

  const { isValid } = formState;
  const fmtFormError = msg => <span className="form-error">{msg}</span>;

  const [optIn, setOptIn] = React.useState(false);

  const handleOptInChange = () => {
    setOptIn(!optIn);
  };

  return (
    <div className="signup-form">
      <form className="form">
        <ApiErrorWrapper
          trigger={Error.API_EMAIL_UNIQUE_MSG}
          msg={Error.EMAIL_UNAVAILABLE}
        >
          <input
            name="email"
            placeholder="email"
            ref={register({
              required: 'email required',
              pattern: {
                value: EMAIL_FMT_PATTERN,
                message: 'not a valid email',
              }
            })}
          />
        </ApiErrorWrapper>
        {errors.email && fmtFormError(errors.email.message)}
        <ApiErrorWrapper
          trigger={Error.API_USERNAME_UNIQUE_MSG}
          msg={Error.USERNAME_UNAVAILABLE}
        >
          <input
            name="username"
            placeholder="username"
            ref={register({
              required: 'username required',
              validate: u => u === u.toLowerCase() || Error.USERNAME_FMT_MSG,
            })}
          />
        </ApiErrorWrapper>
        {errors.username && fmtFormError(errors.username.message)}
        <input
          name="phone"
          placeholder="phone number"
          ref={register({
            required: 'phone required',
            pattern: {
              value: /^[0-9]{10}$/,
              message: 'not a valid phone number',
            },
          })}
        />
        {errors.phone && fmtFormError(errors.phone.message)}
        <input
          type="password"
          name="password"
          placeholder="password"
          ref={
            register({
              required: 'password required',
              minLength: {
                value: MIN_PASSWORD_LENGTH,
                message: Error.PASSWORD_FMT_MSG,
              },
            })}
        />
        {errors.password && fmtFormError(errors.password.message)}
        <input
          type="password"
          name="passwordConfirm"
          placeholder="confirm password"
          ref={
            register({
              required: 'confirm password',
              validate: val => val === watch('password') || 'passwords do not match',
            })}
        />
        {errors.passwordConfirm && fmtFormError(errors.passwordConfirm.message)}
        <div className='opt-in-container'>
            <div role="button" className={classNames('opt-in-button', { 'selected': optIn })} onClick={handleOptInChange} />
            <div className="text-fine-print">
              <p>I agree to recieve messaging about events I am attending at the phone number provided above. I understand  data rates may apply.</p>
            </div>
        </div>
        <div className='opt-in-container'>
          <p className='text-fine-print'>By pressing 'sign up' below, you agree to our <a href="/terms">terms and conditions</a>, our <a href="/privacy-policy">privacy policy</a> and our <a href="/eula">end user license agreement</a>.</p>
        </div>
        <UpdateButton
          text="sign up"
          handlerFunc={handleSubmit(onSubmit)}
          disabled={!optIn}
        />
      </form>
    </div>
  );
};

SignUpForm.propTypes = {
  registerNewUser: PropTypes.func.isRequired,
};

export default SignUpForm;
