import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';

import LogoSvg from '../../assets/newlogo.svg';

const LandingPage: React.FC<{}> = () => {
  return (
    <div className="form">
      <div className="container">
        <h1 className="text-big">Happenings</h1>
        <img loading="eager" className="logo" src={LogoSvg} alt="Happenings Logo" />
        <h2 className="text-big center-text">For social and community organizing</h2>
      </div>
      <div>
      </div>
      <Link className="enter-btn" to="/start">
        <h3 className="text-big">Get started</h3>
      </Link>

      <div className="footer-container">
        <div className="legal-page-links">
          <a href="mailto:hello@whatshappenings.com" target="_blank" rel="noreferrer">Contact</a>
          <a href="/about">About</a>
          <a href="/eula">EULA</a>
          <span><a href="/terms">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a></span>
          {/* <a href="">App Store</a> */}
        </div>
        <h4 className="text-small text">Impossible Effort, Inc {new Date().getFullYear()}</h4>
      </div>
    </div>
  );
};

export default withRouter(LandingPage);
