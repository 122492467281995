import { combineReducers } from 'redux';

import notifications from '@happenings/components/inbox';
import userReducer from '@happenings/components/user';
import postReducer from '@happenings/components/post';
import followReducer from '@happenings/components/entities/follows';
import savesReducer from '@happenings/components/entities/saves';
import comments from '@happenings/components/entities/comments';
import attendance from '@happenings/components/entities/attendance';
import Store from '@happenings/components/store';

export const selectUserFollowing = (state: Store, username: string) => {
  if (username in state.entities.follows) {
    return state.entities.follows[username].following;
  }
  return [];
};

export const selectUserFollowers = (state: Store, username: string) => {
  if (username in state.entities.follows) {
    return state.entities.follows[username].followers;
  }
  return [];
};

export const isFollowing = (state: Store, username: string) => {
  if (state.session.currentUser) {
    const currUsername = state.session.currentUser.username;
    if (username in state.entities.follows && currUsername !== username) {
      if ('followers' in state.entities.follows[username]) {
        return state.entities.follows[username].followers
          .map(follower => follower.username).includes(currUsername);
      }
    }
  }
  return false;
};


const entitiesReducer = combineReducers({
  users: userReducer,
  posts: postReducer,
  follows: followReducer,
  stars: savesReducer,
  attendance,
  comments,
  notifications,
});

export default entitiesReducer;
