import { FetchAction } from '@happenings/components/common/types';

export type Follower = {
  follower_id: number;
  username: string;
  display_name: string;
  awaiting_approval: boolean;
};

export type FollowContainer = {
  followers: Follower[];
  following: Follower[];
};

export type FollowState = Record<string, FollowContainer>;

const INITIAL_STATE: FollowState = {};

export const actionTypes: Record<string, string> = {
  FETCH_FOLLOW_DATA: 'FETCH_FOLLOW_DATA',
  RECEIVE_FOLLOWER_DATA: 'RECEIVE_FOLLOWER_DATA',
  RECEIVE_FOLLOWING_DATA: 'RECEIVE_FOLLOWING_DATA',

  GET_FOLLOWERS: 'GET_FOLLOWERS',
  GET_FOLLOWING: 'GET_FOLLOWING',
  UPDATE_FOLLOW: 'UPDATE_FOLLOW',
  APPROVE_FOLLOW: 'APPROVE_FOLLOW',
};

/**
 * Action Creators
 */
export const getFollowers = (username: string): FetchAction => ({
  type: actionTypes.GET_FOLLOWERS,
  payload: { username },
});

export const getFollowing = (username: string): FetchAction => ({
  type: actionTypes.GET_FOLLOWING,
  payload: { username },
});

export const updateFollow = (followeeUsername: string, val: boolean): FetchAction => ({
  type: actionTypes.UPDATE_FOLLOW,
  payload: { username: followeeUsername, val },
});

export const approveFollow = (currUsername: string, followerName: string): FetchAction => ({
  type: actionTypes.APPROVE_FOLLOW,
  payload: { username: currUsername, followerName },
});

const follows = (state = INITIAL_STATE, action): FollowState => {
  const { username, data } = action;
  switch (action.type) {
    case actionTypes.RECEIVE_FOLLOWER_DATA:
      return {
        ...state,
        [username]: { ...state[username], followers: data },
      };
    case actionTypes.RECEIVE_FOLLOWING_DATA:
      return {
        ...state,
        [username]: { ...state[username], following: data },
      };
    default:
      return state;
  }
};

export default follows;
