import React from 'react';

import LandingPage from './auth/LandingPage';

const PublicLanding = () => {
  return (
    <div className="public-landing-container">
      <LandingPage />
    </div>
  );
};

export default PublicLanding;
