import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { formatDate } from '@happenings/components/post';

class SearchResultDropdown extends React.Component {
  handleClick(entity) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.props.updateSearchedEntity(entity);
    };
  }

  render() {
    const { searchedEntity, userResults, postResults, searchQuery } = this.props;

    const userList = userResults && userResults.map(user => (
      <Link to={`/user/${user.username}`} key={`user-${user.id}`}>
        <li>
          <section className="post-result">
            <strong className="post-title">{user.display_name || user.username}</strong>
            <div>
              {
                user.display_name && (<p className="post-details">
                  <span className="text-fine-print">@{user.username}</span>
                </p>)
              }
              <p className="post-details">
                <span className='text-fine-print'>
                  {user.bio && user.bio.length > 50
                    ? `${user.bio.substring(0, 50)}...`
                    : user.bio
                  }
                </span>
              </p>
            </div>
          </section>
        </li>
      </Link>
    ));

    const postList = postResults.map(post => (
      <Link to={`/event/${post.id}`} key={`post-${post.id}`}>
        <li>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: '100%' }}>
            <section className="post-result">
              <strong className="post-title">
                {post.title.length <= 33 ? post.title : `${post.title.substring(0, 30)}...`}
              </strong>
              <p className="post-details">
                <span className="text-fine-print">{formatDate(post.eventTimestamp)}</span>
              </p>
              <p className="post-details">
                <span className="text-fine-print">hosted by {`@${post.username}`}</span>
              </p>
            </section>
            <img src={post.thumbPublicUrl} style={{ borderRadius: 5 }} />
          </div>
        </li>
      </Link>
    ));

    const usersSelected = classNames({ 'selected': searchedEntity === 'users' });
    const postsSelected = classNames({ 'selected': searchedEntity === 'posts' });
    const results = (searchedEntity === 'users') ? userList : postList;

    const hidden = classNames({
      'hidden': searchQuery.length < 1,
    });

    return (
      <section style={{ zIndex: 1 }} className={`search-dropdown ${hidden}`}>
        <ul className='entity-picker'>
          <li className={usersSelected} onClick={this.handleClick('users')}>Users</li>
          <li className={postsSelected} onClick={this.handleClick('posts')}>Events</li>
        </ul>
        <ul className="results">
          {results}
        </ul>
      </section>
    );
  }
}

SearchResultDropdown.defaultProps = {
  userResults: [],
  postResults: [],
};

SearchResultDropdown.propTypes = {
  userResults: PropTypes.arrayOf(PropTypes.object),
  postResults: PropTypes.arrayOf(PropTypes.object),
  updateSearchedEntity: PropTypes.func,
  searchQuery: PropTypes.string,
  searchedEntity: PropTypes.string,
};

export default SearchResultDropdown;
