import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ImageUploader from './ImageUploader';

import { loadLocalImage, clearLocalImage } from '@happenings/components/create';

const mapDispatchToProps = dispatch => ({
  clearImage: () => dispatch(clearLocalImage()),
  loadImage: fileRef => dispatch(loadLocalImage(fileRef)),
});

const ImageHandler = ({ showPreview, clearImage, loadImage, imgUrl }) => {
  const preview = (
    <div>
      <span role="button" onClick={clearImage}>&times;</span>
      <img alt="event poster" src={imgUrl} width="300" />
    </div>
  );
  return (
    <div className="image-container">
      {
        showPreview
          ? preview : (
            <ImageUploader
              clearLocalImage={clearImage}
              onDrop={file => loadImage(file)}
            />
          )
      }
    </div>
  );
};

ImageHandler.propTypes = {
  showPreview: PropTypes.bool.isRequired,
  clearImage: PropTypes.func.isRequired,
  loadImage: PropTypes.func.isRequired,
  imgUrl: PropTypes.string,
};

ImageHandler.defaultProps = {
  imgUrl: undefined,
};

export default connect(
  null,
  mapDispatchToProps,
)(ImageHandler);
