import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import * as Error from '@happenings/components/errors';
import { MIN_PASSWORD_LENGTH, EMAIL_FMT_PATTERN } from '@happenings/components/constants';

import CheckBoxField from '../create/CheckBoxField';
import KeySelector from '../util/KeySelector';
import UpdateButton from '../util/UpdateButton';
import ApiErrorWrapper from '../util/ErrorWrapper';


const AccountSettingsForm = (props) => {
  const defaultValues = {
    isPrivate: props?.isPrivate || false, // consistency w/ default val in DB
    userType: 'PERSON', // consistency w/ default val in DB
  };
  const { register, handleSubmit, setValue, watch, errors } = useForm({ defaultValues });

  useEffect(() => {
    register({ name: 'isPrivate' });
    register({ name: 'userType' });
  }, [register]);

  useEffect(() => {
    setValue('username', props.username);
    setValue('displayName', props.displayName);
    setValue('email', props.email);
    setValue('bio', props.bio);
    setValue('isPrivate', props.isPrivate);
    setValue('userType', props.userType);
  }, [props.username, props.email, props.bio, props.isPrivate, props.userType]);
  const { isPrivate, userType } = watch();

  const fmtFormError = message => <span className="form-error">{message}</span>;

  return (
    <form className="form">
      <div className="acct-settings-row">
        <label htmlFor="username">Username</label>
        <ApiErrorWrapper trigger={Error.API_USERNAME_UNIQUE_MSG} msg="username unavailable">
          <input
            name="username"
            ref={register({
              required: true,
              validate: u => u === u.toLowerCase() || Error.USERNAME_FMT_MSG,
            })}
            placeholder="Username"
          />
        </ApiErrorWrapper>
      </div>
      {errors.username && fmtFormError(errors.username?.message)}

      <div className="acct-settings-row">
        <label htmlFor="displayName">Display Name</label>
        <input

          name="displayName"
          ref={register({
            required: 'display name required',
            maxLength: {
              value: 30,
              message: 'display name must be 30 characters or less',
            },
          })}
          placeholder="Display Name"
        />
      </div>
      {errors.displayName && fmtFormError(errors.displayName?.message)}


      <div className="acct-settings-row">
        <label htmlFor="email">Email</label>
        <ApiErrorWrapper
          trigger={Error.API_EMAIL_UNIQUE_MSG}
          msg="an account with this email already exists"
        >
          <input
            name="email"
            placeholder="Email"
            ref={register({
              required: 'email required',
              pattern: {
                value: EMAIL_FMT_PATTERN,
                message: 'not a valid email',
              }
            })}
            defaultValue={props.username}
          />
        </ApiErrorWrapper>
      </div>

      {errors.email && fmtFormError(errors.email?.message)}

      <div className="acct-settings-row">

        <label htmlFor="bio">Bio</label>
        <div> {/* temporary div meant to be replaced by ApiErrorWrapper */}

          <textarea
            name="bio"
            placeholder="Bio"
            ref={register({
              maxLength: {
                value: 60,
                message: 'bio must be 60 characters or less',
              },
            })}
            defaultValue={props.bio}
          />
        </div>

      </div>
      <div className="acct-settings-row">
        <label htmlFor="password">Password</label>
        <div> {/* temporary div meant to be replaced by ApiErrorWrapper */}


          <input
            name="password"
            type="password"
            placeholder="Password"
            ref={register({
              validate: u => (!u || u.length > MIN_PASSWORD_LENGTH) || `password must be greater than ${MIN_PASSWORD_LENGTH}`,
            })}
            defaultValue={null}
          />


        </div>
      </div>

      {errors.password && fmtFormError(errors.password?.message)}
      <div className="acct-settings-row">
        <div id="invisible-label-space"></div> {/* space where label would generally go */}
        <div> {/* temporary div meant to be replaced by ApiErrorWrapper */}
          <input
            name="passwordConfirm"
            type="password"
            placeholder="Confirm Password"
            defaultValue={null}
            ref={register({
              validate: v => v === watch('password') || 'passwords do not match',
            })}
          />
        </div>
      </div>
      {errors.passwordConfirm && fmtFormError(errors.passwordConfirm?.message)}

      <div className="acct-settings-row">
        <label>Profile Type</label>
        <KeySelector
          keyList={['PERSON', 'PLACE', 'THING']}
          containerClass="checkbox-container"
          btnNameMap={{
            'PERSON': 'Person',
            'PLACE': 'Place',
            'THING': 'Thing',
          }}
          btnClass="checkbox age-btn"
          selection={userType}
          onChange={v => setValue('userType', v)}
        />
      </div>
      <div className="acct-settings-row">
        <label>Private Account</label>
        <CheckBoxField
          field="isPrivate"
          isSelected={isPrivate}
          label=""  //maybe do not sent this for styling purposes
          handleChange={(_, v) => setValue('isPrivate', v)}
        />
      </div>
      <UpdateButton
        text="Save Changes"
        handlerFunc={handleSubmit(props.onSubmit)}
      />
    </form>
  );
};

AccountSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  bio: PropTypes.string,
  isPrivate: PropTypes.bool.isRequired,
  userType: PropTypes.string,
};

AccountSettingsForm.defaultProps = {
  bio: '',
  userType: 'PERSON',
};

export default AccountSettingsForm;
