import React from 'react';
import PropTypes from 'prop-types';

import { Switch, Route, Redirect } from 'react-router-dom';

import DiscoverFeedContainer from './feed/DiscoverFeed';
import MainFeedContainer from './feed/MainFeedContainer';

import PublicLanding from './PublicLanding';
import VerifyEmailPage from './auth/VerifyEmailPage';
import VerifyEmailForm from './auth/VerifyEmailForm';

import VerifyPhoneNumber from './auth/VerifyPhoneNumber';
import PhoneSignup from './auth/PhoneNumberSignup';

// import ForgotPasswordForm from './auth/ForgotPassword';
import ResetPasswordPage from './auth/ResetPasswordPage';
import SignUpFormContainer from './auth/SignUpFormContainer';
import LoginFormContainer from './session/LoginFormContainer';

import ProfilePageContainer from './profile/ProfilePageContainer';
import FollowPage from './profile/FollowPage';

import PostPageContainer from './post/PostPageContainer';
import EditPostContainer from './create/EditPostContainer';
import AttendancePage from './post/AttendanceList';
import CommentPage from './post/CommentPage';

// public routes
import SupportPage from './public/SupportPage';
import TermsAndConditions from './public/TermsAndConditions';
import Privacy from './public/Privacy';
import About from './public/About';
import EULA from './public/EULA';

import AccountSettingsContainer from './profile/AccountSettingsContainer';

import InboxContainer from './inbox/InboxContainer';

import NotFoundPage from './errors/Page404';
import ForgotPassword from './auth/ForgotPassword';

// POST PREVIEW
import PostPreviewPageContainer from './post/PostPreviewPageContainer';


const Main = ({ loggedIn }) => {
  const authed = Component => (
    loggedIn
      ? Component
      : () => <Redirect to="/" />
  );

  return (
    <main>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (loggedIn ? <MainFeedContainer /> : <PublicLanding />)}
        />
        <Route path="/discover" component={DiscoverFeedContainer} />
        {/* <Route path="/signup" component={SignUpFormContainer} /> */}
        <Route path="/start" component={PhoneSignup} />
        <Route path="/verify-phone" component={VerifyPhoneNumber} />
        <Route path="/contact" component={SupportPage} />
        <Route path="/terms" component={TermsAndConditions} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/about" component={About} />
        <Route path="/eula" component={EULA} />
        <Route path="/login" render={() => (!loggedIn ? <LoginFormContainer /> : <Redirect to="/" />)} />
        <Route path="/verify-email" render={() => (!loggedIn ? <VerifyEmailPage /> : <Redirect to="/" />)} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/send-verification-email" render={() => (!loggedIn ? <VerifyEmailForm /> : <Redirect to="/" />)} />
        <Route path="/user/:username/followers" component={authed(FollowPage)} />
        <Route path="/user/:username/following" component={authed(FollowPage)} />
        <Route
          path="/user/:username"
          component={authed(ProfilePageContainer)}
        />
        <Route
          path="/event/:event_id/attendance"
          component={authed(AttendancePage)}
        />
        <Route path="/event/:event_id/edit" component={EditPostContainer} />
        <Route
          path="/event/:event_id/comments"
          render={props => (
            <CommentPage {...props} />
          )}
        />
        <Route path="/event/:event_id" component={PostPageContainer} />

        {/* POST PREVIEW */}
        <Route path="/event-preview/:event_id" component={PostPreviewPageContainer} />

        <Route path="/settings" component={authed(AccountSettingsContainer)} />
        <Route
          path="/create"
          render={props => (
            <EditPostContainer {...props} isNewEvent />
          )}
        />
        <Route path="/inbox" component={authed(InboxContainer)} />

        {/* the wildcard route handler is a 404 page */}
        <Route component={NotFoundPage} />
      </Switch>
    </main>
  );
};

Main.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default Main;
