import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getFollowing, updateFollow } from '@happenings/components/entities/follows';
import { Attendance, getAttendance, conjugateAttendanceStatus } from '@happenings/components/entities/attendance';

// import { currentUserFollowing } from '../../reducers/selectors';

import LoadingIcon from '../common/LoadingIcon';


const isLoaded = (state, postId) => (postId in state.entities.attendance);

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.session.currentUser,
  postId: ownProps.match.params.event_id,
  attendanceList: state.entities.attendance[ ownProps.match.params.event_id ],
  // followData: state.entities.follows,
  // followingList: currentUserFollowing(state),
  loaded: isLoaded(state, ownProps.match.params.event_id),
});


const mapDispatchToProps = dispatch => ({
  init: (postId, username) => {
    dispatch(getAttendance(postId));
    dispatch(getFollowing(username));
  },
  followHandler: (followeeUsername, val) => (
    dispatch(updateFollow(followeeUsername, val))
  ),
});

const AttendanceRow: React.FC<{ attendee: Attendance; }> = ({ attendee }) => {
  return (
    <div className="follow-row">
      <Link to={`/user/${attendee.username}`}>{attendee.display_name || attendee.username}</Link>
      <span>{conjugateAttendanceStatus(attendee.status)}</span>
    </div>
  );
};


// eslint-disable-next-line max-len
const AttendanceList = ({ currentUser, postId, init, attendanceList, loaded }) => {
  useEffect(() => init(postId, currentUser.username), []);

  // I decided to remove the follow button from this list.. it's heavy and doesn't provide much value.
  // tgoodwin 01-02-24

  // const followHelper = (username, val) => followHandler(username, val);

  const renderAttendees = attendees => (
    <div className="attendance-list">
      {attendees.map((attendee: Attendance) => {
        // const currUserIsFollowing = followingList.includes(attendee.username);
        return (
          <AttendanceRow attendee={attendee} key={attendee.username} />
        );
      })}
    </div>
  );

  return (
    <div className="attendance-container">
      <div className="header">Going</div>
      {loaded ? renderAttendees(attendanceList) : <LoadingIcon />}
    </div>
  );
};


AttendanceList.propTypes = {
  currentUser: PropTypes.shape({
    username: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  postId: PropTypes.string.isRequired,
  init: PropTypes.func.isRequired,
  followHandler: PropTypes.func.isRequired,
  followingList: PropTypes.arrayOf(PropTypes.string),
  attendanceList: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
    }),
  ),
  loaded: PropTypes.bool.isRequired,
};

AttendanceList.defaultProps = {
  followingList: [],
  attendanceList: [],
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AttendanceList));
