const actionTypes = {
  RECEIVE_POST_STARS: 'RECEIVE_POST_STARS',
}

export interface Save {
  user_id: number;
  display_name: string;
  username: string;
}

export type State = Record<number, Save[]>;

const reducer = (state = {}, action): State => {
  switch (action.type) {
    case actionTypes.RECEIVE_POST_STARS:
      return {
        ...state,
        [action.postId]: action.stars,
      };
    default:
      return state;
  }
};

export default reducer;