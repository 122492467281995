import * as React from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { getFetchParams } from '@happenings/components/util';

import UpdateButton from '../util/UpdateButton';
import { EMAIL_FMT_PATTERN } from '../../constants';

const ForgotPassword: React.FC<{}> = () => {
  type formFields = { email: string; };
  const { register, handleSubmit, errors, formState } = useForm<formFields>({
    mode: 'onSubmit',
  });
  const { isValid } = formState;

  const [ submitted, setSubmitted ] = React.useState(false);

  const onSubmit = (data: formFields) => {
    axios.post(
      `/api/auth/forgot-password`,
      { email: data.email },
      getFetchParams(),
    );
    // optimistic UI update
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <div>
        Thanks! If an account with this email exists, you'll receive a link to reset your password
        in your inbox.
      </div>
    );
  }

  return (
    <div className="form">
      <div>
        Enter your email and we'll send you a link to reset your password.
      </div>
      <form>
        <input
          type="text"
          name="email"
          placeholder="email"
          ref={register({
            required: 'email required',
            pattern: {
              value: EMAIL_FMT_PATTERN,
              message: 'invalid email',
            },
          })}
        />
        {errors.email && (
          <span className="form-error">{errors.email.message}</span>
        )}
        <UpdateButton
          text="Send"
          handlerFunc={handleSubmit(onSubmit)}
        />
      </form>
    </div>
  );
};

export default ForgotPassword;
